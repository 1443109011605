import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import { paths } from 'src/routes/paths';
import Scrollbar from 'src/components/scrollbar';
import { usePathname } from 'src/routes/hooks';
import { NavSectionVertical } from 'src/components/nav-section';
//
import { NAV } from '../config-layout';
import { useNavData, useNavDataSuperAdmin } from './config-navigation';
import { NavToggleButton, NavUpgrade } from '../_common';
import { rolepremissionsGet } from 'src/redux/features/premission/premissionSlice';
import { billingTypeCheck } from 'src/redux/features/fiscal-year/fiscalYearSlice';

export default function NavVertical({ openNav, onCloseNav }) {
  const pathname = usePathname();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lgUp = useResponsive('up', 'lg');
  const navData = useNavData();
  const superAdminData = useNavDataSuperAdmin();

  const { currentUser } = useSelector((state) => state.auth);
  const { selectedBranch } = useSelector((state) => state.branch);
  const { billingType } = useSelector((state) => state.fiscalYear);
  const { premissionAgainstRole } = useSelector((state) => state.premission);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (currentUser?.userType != 1 && selectedBranch?._id) {
      dispatch(billingTypeCheck(selectedBranch?._id))
    }
  }, [selectedBranch]);

  useEffect(() => {
    if (selectedBranch?.role) {
      dispatch(rolepremissionsGet(selectedBranch?.role?._id));
    }
  }, [selectedBranch]);

  const filterNavData = (navData, premissionAgainstRole, selectedBranch) => {
    if (!selectedBranch?.role) {
      if (selectedBranch?.trialFree) {
        return navData;
      }
      else if (!selectedBranch?.trialFree && selectedBranch?.subscription?.plan?.type == 'pro') {
        return navData;
      }
      else if (!selectedBranch?.trialFree && selectedBranch?.subscription?.plan?.type == 'free') {
        return navData?.map((section) => {
          const filteredItems = section?.items?.map((item) => {
            if (
              ["reports", "users", "accounting", "settings", "roles & permission", "account access", "dashboard", "subscription", "company"]
                .includes(item?.title?.toLowerCase())
            ) {
              if (item?.children) {
                const filteredChildren = item?.children?.filter(
                  (child) =>
                    !["VAT Declaration", "Balance Tax Rate", "VAT Report"]?.includes(child?.title)
                );
                return { ...item, children: filteredChildren };
              }
              return item;
            }
            return null;
          }).filter(Boolean);

          if (filteredItems?.length > 0) {
            return { ...section, items: filteredItems };
          }
          return null;
        }).filter(Boolean);
      }
      else if(selectedBranch?.trialFree == false){
        return navData?.map((section) => {
          const filteredItems = section?.items?.filter((item) =>
              ["account access","subscription"].includes(item?.title?.toLowerCase())
          );
          return filteredItems?.length > 0 ? { ...section, items: filteredItems } : null;
      }).filter(Boolean);
      }
      else{
        return navData;
      }
    }
    const allowedTitles = new Set(
      premissionAgainstRole?.map((permission) => permission?.title?.toLowerCase())
    );
    const freePlanPermissions = new Set(['reports','users','accounting','settings']);
    const isTrialOrPro =selectedBranch?.trialFree === true || selectedBranch?.subscription?.plan?.type === 'pro';
    const isFree =selectedBranch?.subscription?.plan?.type === 'free';
    const isNotTrial = selectedBranch?.trialFree === false;

    return navData
      ?.map((section) => {
        const filteredItems = section?.items?.filter((item) => {
          if (item?.title?.toLowerCase() == 'dashboard' || item?.title?.toLowerCase() == 'account access') return true;
          if (isTrialOrPro) return allowedTitles?.has(item?.title?.toLowerCase());
          if (isFree) return allowedTitles.has(item?.title?.toLowerCase()) && freePlanPermissions.has(item?.title?.toLowerCase());
          if (isNotTrial) return false;
          return allowedTitles?.has(item?.title?.toLowerCase());
        });

        if (filteredItems?.length > 0) {
          return { ...section, items: filteredItems };
        }
        return null;
      })
      .filter(Boolean);
  };

  const filterNavItemsByBillingType = (navData, billingType) => {
    return navData.map((section) => {
      if (section?.items) {
        const updatedItems = section?.items?.map((item) => {
          if (item?.title?.toLowerCase() == 'accounting' && item?.children) {
            const filteredChildren = item?.children?.filter((child) => {
              if (child?.title == 'VAT Declaration') {
                return billingType?.effective;
              }
              if (child?.title == 'Balance Tax Rate') {
                return billingType?.netTaxRate;
              }
              return true;
            });
            return { ...item, children: filteredChildren };
          }
          if (item?.title?.toLowerCase() == 'reports' && item?.children) {
            const filteredChildren = item?.children?.filter((child) => {
              if (child?.title == 'VAT Report') {
                return billingType?.effective || billingType?.netTaxRate;
              }
              return true;
            });
            return { ...item, children: filteredChildren };
          }

          return item;
        });
        return { ...section, items: updatedItems };
      }
      return section;
    });
  };

  const filteredNavData = filterNavData(navData, premissionAgainstRole, selectedBranch);
  const finalNavData = filterNavItemsByBillingType(filteredNavData, billingType);

  const isValidPath = (pathname, filteredNavData) => {
    return filteredNavData?.some((section) =>
      section.items.some((item) => {
        if (item?.path === pathname || pathname?.startsWith(item?.path)) {
          return true;
        }
        if (item?.children) {
          return item?.children?.some((child) => child?.path === pathname || pathname?.startsWith(child?.path));
        }
        return false;
      })
    );
  };

  useEffect(() => {
    if (currentUser?.userType === 1 && !isValidPath(pathname, superAdminData)) {
      navigate(paths.superAdmin.root);
    } else if (currentUser?.userType != 1 && !isValidPath(pathname, finalNavData)) {
      navigate(paths.dashboard.root);
    }
  }, [pathname, finalNavData, navigate]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Logo sx={{ mt: 3, ml: 4, mb: 1 }} />
      {currentUser?.userType && currentUser?.userType == 1 ?
        <NavSectionVertical
          data={superAdminData}
          config={{
            currentRole: 'superAdmin',
          }}
        /> :
        <NavSectionVertical
          data={finalNavData}
          config={{
            currentRole: 'admin',
          }}
        />
      }
      <Box sx={{ flexGrow: 1 }} />
      <NavUpgrade />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

NavVertical.propTypes = {
  onCloseNav: PropTypes.func,
  openNav: PropTypes.bool,
};
