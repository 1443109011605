import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const toggleSubscription = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/subscription`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add Subscription api failed');
};
const subscriptionGet = async (token,branch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/subscription?branch=${branch}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Subscription List api failed');
};
const subscriptionServices = {
    toggleSubscription,
    subscriptionGet,
};
export default subscriptionServices;
