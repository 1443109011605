import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const addTransaction = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/transaction`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add Transaction api failed');
};
const transaction = async (token, pageno, limit, branch, search, startDate, endDate, sortField, sortOrder) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/transaction?branch=${branch}&page=${pageno}&perPage=${limit}&startDate=${startDate ?? ''}&endDate=${endDate ?? ''}&search=${search ?? ''}&sortField=${sortField}&sortOrder=${sortOrder}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Transaction List api failed');
};
const updateTransaction = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/transaction/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated Transaction api failed');
};
const deleteTransaction = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.delete(
    `${BASE_URL}/admin/transaction/${id}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Delete Transaction api failed");
};
const taxForm = async (token, branch, startDate, endDate) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/taxForm?branch=${branch}&startDate=${startDate ?? ''}&endDate=${endDate ?? ''}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Tax Form api failed');
};
const recentTransactionGet = async (token, branch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/transaction/recent?branch=${branch}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Recent Transaction List api failed');
};
const deleteMultipleTransaction = async (token, ids) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ids,
    },
  };
  const response = await axios.delete(
    `${BASE_URL}/admin/transactions/multiple`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Delete Transaction api failed");
};
const transactionByAccountChartGet = async (token,accountChartId,sortField,sortOrder,search,startDate,endDate) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/transaction/${accountChartId}?sortField=${sortField}&sortOrder=${sortOrder}&startDate=${startDate ?? ''}&endDate=${endDate ?? ''}&search=${search ?? ''}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Transactions By AccountChart api failed');
};
const transactionsByAccountChartPDFGet= async (token,accountChartId,sortField,sortOrder,search,startDate,endDate) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  };
  const response = await axios.get(
    `${BASE_URL}/admin/transaction/${accountChartId}/pdf?sortField=${sortField}&sortOrder=${sortOrder}&startDate=${startDate ?? ''}&endDate=${endDate ?? ''}&search=${search ?? ''}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Transactions By AccountChart PDF api failed');
};
const transactionPdf = async (token,branch,search,startDate,endDate,sortField,sortOrder) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  };
  const response = await axios.get(
    `${BASE_URL}/admin/pdf/transaction?branch=${branch}&startDate=${startDate ?? ''}&endDate=${endDate ?? ''}&search=${search ?? ''}&sortField=${sortField}&sortOrder=${sortOrder}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Transaction PDF api failed');
};
const transactionServices = {
  addTransaction,
  transaction,
  transactionPdf,
  updateTransaction,
  deleteTransaction,
  taxForm,
  recentTransactionGet,
  deleteMultipleTransaction,
  transactionByAccountChartGet,
  transactionsByAccountChartPDFGet
};

export default transactionServices;
