import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const addSubscriptionPlan = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/superAdmin/subscriptionPlan`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add Subscription Plan api failed');
};
const subscriptionPlan = async (token,pageno,limit) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/superAdmin/subscriptionPlan?page=${pageno}&perPage=${limit}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Subscription Plan List api failed');
};
const updateSubscriptionPlan = async (token,id,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/superAdmin/subscriptionPlan/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated Subscription Plan api failed');
};
const deleteSubscriptionPlan = async (token,id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.delete(
    `${BASE_URL}/superAdmin/subscriptionPlan/${id}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error("Delete Subscription Plan api failed");
};
const activeSubscriptionPlan = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/superAdmin/subscriptionPlan/active`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Active Subscription Plan List api failed');
};
const subscriptionPlanServices = {
  addSubscriptionPlan,
  subscriptionPlan,
  updateSubscriptionPlan,
  deleteSubscriptionPlan,
  activeSubscriptionPlan
};
export default subscriptionPlanServices;
