import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import subscriptionServices from './subscriptionServices';

const initialState = {
  subscription:{},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

export const toggleSubscription = createAsyncThunk('toggleSubscription', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await subscriptionServices.toggleSubscription(user.accesstoken,data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const subscriptionGet = createAsyncThunk(
  'subscriptionGet',
  async (branch, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await subscriptionServices.subscriptionGet(user.accesstoken,branch);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(toggleSubscription.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(toggleSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.subscription = action?.payload?.body;
      })
      .addCase(toggleSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(subscriptionGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(subscriptionGet.fulfilled, (state, action) => {
        state.subscription = action?.payload?.body;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(subscriptionGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.subscription = {};
      });
  },
});

export const { resets } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
