import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import subscriptionPlanServices from './subscriptionPlanServices';

const initialState = {
  subscriptionPlans:[],
  activeSubscriptionPlans:[],
  totalRecord: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

export const addSubscriptionPlan = createAsyncThunk('addSubscriptionPlan', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await subscriptionPlanServices.addSubscriptionPlan(user.accesstoken,data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const subscriptionPlanGet = createAsyncThunk(
  'subscriptionPlanGet',
  async ({pageno,limit}, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await subscriptionPlanServices.subscriptionPlan(user.accesstoken,pageno,limit);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateSubscriptionPlan = createAsyncThunk('updateSubscriptionPlan',
  async ({ id, data }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await subscriptionPlanServices.updateSubscriptionPlan(user.accesstoken, id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteSubscriptionPlan = createAsyncThunk("deleteSubscriptionPlan",async (id, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await subscriptionPlanServices.deleteSubscriptionPlan(user.accesstoken, id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const activeSubscriptionPlan = createAsyncThunk(
  'activeSubscriptionPlan',
  async (thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await subscriptionPlanServices.activeSubscriptionPlan(user.accesstoken);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const subscriptionPlanSlice = createSlice({
  name: 'subscriptionPlan',
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addSubscriptionPlan.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(addSubscriptionPlan.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.subscriptionPlans = state.subscriptionPlans.concat({ ...action?.payload?.body });
      })
      .addCase(addSubscriptionPlan.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(subscriptionPlanGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(subscriptionPlanGet.fulfilled, (state, action) => {
        state.subscriptionPlans = action?.payload?.body;
        state.totalRecord = action?.payload?.total_record;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(subscriptionPlanGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.subscriptionPlans = [];
      })
      .addCase(updateSubscriptionPlan.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateSubscriptionPlan.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.subscriptionPlans = state.subscriptionPlans.map((item) =>
          item._id === action?.payload?.body?._id ? action?.payload?.body : item
        );
      })
      .addCase(updateSubscriptionPlan.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(deleteSubscriptionPlan.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(deleteSubscriptionPlan.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.subscriptionPlans = state.subscriptionPlans?.filter((item) => item?._id !== action?.payload?.body?._id);
      })
      .addCase(deleteSubscriptionPlan.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(activeSubscriptionPlan.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(activeSubscriptionPlan.fulfilled, (state, action) => {
        state.activeSubscriptionPlans = action?.payload?.body;
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
      })
      .addCase(activeSubscriptionPlan.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { resets } = subscriptionPlanSlice.actions;

export default subscriptionPlanSlice.reducer;
