import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import transactionServices from './transactionServices';

const initialState = {
  transactions: [],
  taxForm: [],
  totalRecord: null,
  isError: false,
  isSuccess: false,
  isLoadingPdf: false,
  isLoading: false,
  message: '',
};

export const addTransaction = createAsyncThunk('addTransaction', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await transactionServices.addTransaction(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const transactionGet = createAsyncThunk(
  'transactionGet',
  async ({ pageno, branch, limit, search, startDate, endDate, sortField, sortOrder }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await transactionServices.transaction(
        user.accesstoken,
        pageno,
        limit,
        branch,
        search,
        startDate,
        endDate,
        sortField,
        sortOrder
      );
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateTransaction = createAsyncThunk(
  'updateTransaction',
  async ({ id, data }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await transactionServices.updateTransaction(user.accesstoken, id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteTransaction = createAsyncThunk(
  "deleteTransaction",
  async (id, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await transactionServices.deleteTransaction(user.accesstoken, id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const taxFormGet = createAsyncThunk(
  'taxFormGet',
  async ({ branch, startDate, endDate }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await transactionServices.taxForm(user.accesstoken, branch, startDate, endDate);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const recentTransactionGet = createAsyncThunk('recentTransactionGet', async (branch, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await transactionServices.recentTransactionGet(user.accesstoken, branch);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
}
);
export const deleteMultipleTransaction = createAsyncThunk(
  "deleteMultipleTransaction",
  async (ids, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await transactionServices.deleteMultipleTransaction(user.accesstoken, ids);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const transactionByAccountChartGet = createAsyncThunk('transactionByAccountChartGet', async ({ accountChartId, sortField, sortOrder, search, startDate, endDate }, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await transactionServices.transactionByAccountChartGet(user.accesstoken, accountChartId, sortField, sortOrder, search, startDate, endDate);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
}
);
export const transactionsByAccountChartPDFGet = createAsyncThunk('transactionsByAccountChartPDFGet', async ({ accountChartId, sortField, sortOrder, search, startDate, endDate }, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await transactionServices.transactionsByAccountChartPDFGet(user.accesstoken, accountChartId, sortField, sortOrder, search, startDate, endDate);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
}
);
export const transactionPdf = createAsyncThunk(
  'transactionPdf',
  async ({branch,search,startDate,endDate,sortField,sortOrder}, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await transactionServices.transactionPdf(
        user.accesstoken,
        branch,
        search,
        startDate,
        endDate,
        sortField,
        sortOrder
      );
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isLoadingPdf = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addTransaction.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(addTransaction.fulfilled, (state, action) => {
        state.transactions = [{ ...action?.payload?.body }, ...state.transactions];
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(addTransaction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(transactionGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(transactionGet.fulfilled, (state, action) => {
        state.transactions = action?.payload?.body;
        state.totalRecord = action?.payload?.total_record;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(transactionGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.transactions = [];
      })
      .addCase(updateTransaction.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateTransaction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.transactions = state.transactions.map((item) =>
          item._id === action?.payload?.body?._id ? action?.payload?.body : item
        );
      })
      .addCase(updateTransaction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(taxFormGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(taxFormGet.fulfilled, (state, action) => {
        state.taxForm = action?.payload?.body;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(taxFormGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteTransaction.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(deleteTransaction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.transactions = state.transactions.filter((item) => item?._id !== action?.payload?.body?._id);
      })
      .addCase(deleteTransaction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(recentTransactionGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(recentTransactionGet.fulfilled, (state, action) => {
        state.transactions = action?.payload?.body;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(recentTransactionGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.transactions = [];
      })
      .addCase(deleteMultipleTransaction.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(deleteMultipleTransaction.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(deleteMultipleTransaction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(transactionByAccountChartGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(transactionByAccountChartGet.fulfilled, (state, action) => {
        state.transactions = action?.payload?.body;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(transactionByAccountChartGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.transactions = [];
      })
      .addCase(transactionsByAccountChartPDFGet.pending, (state) => {
        state.isLoadingPdf = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(transactionsByAccountChartPDFGet.fulfilled, (state) => {
        state.isSuccess = true;
        state.isLoadingPdf = false;
        state.isError = false;
      })
      .addCase(transactionsByAccountChartPDFGet.rejected, (state, action) => {
        state.isLoadingPdf = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(transactionPdf.pending, (state) => {
        state.isLoadingPdf = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(transactionPdf.fulfilled, (state) => {
        state.isSuccess = true;
        state.isLoadingPdf = false;
        state.isError = false;
      })
      .addCase(transactionPdf.rejected, (state, action) => {
        state.isLoadingPdf = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { resets } = transactionSlice.actions;

export default transactionSlice.reducer;
