import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
// import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const CompanyPage = lazy(() => import('src/pages/dashboard/Company/list'));
const AllUserPage  = lazy(() => import('src/pages/dashboard/all-user/list'));
const SubscriptionPlanPage  = lazy(() => import('src/pages/dashboard/subscription-plan/list'));
const AboutCardPage = lazy(() => import('src/pages/dashboard/about-us'));
const PrivacyCardPage = lazy(() => import('src/pages/dashboard/privacy'));
const FaqsCardPage = lazy(() => import('src/pages/dashboard/faqs'));
const TermAndConditionCardPage = lazy(() => import('src/pages/dashboard/term-and-condition'));
const ContactUsPage = lazy(() => import('src/pages/dashboard/contact-us'));

export const superAdminRoutes = [
  {
    path: 'superAdmin',
    element: (
      // <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      //  </AuthGuard>
    ),
    children: [
      { path :'dashboard', element: <IndexPage />, index: true },
      {
        path: 'company',
        children: [
          { element: <CompanyPage />, index: true },
        ],
      },
      {
        path: 'user',
        children: [
          { element: <AllUserPage />, index: true },
        ],
      },
      {
        path: 'subscription-plan',
        children: [
          { element: <SubscriptionPlanPage />, index: true },
        ],
      },
      {
        path: 'aboutUs',
        children: [
          { element: <AboutCardPage />, index: true },
        ],
      },
      {
        path: 'privacy-policy',
        children: [
          { element: <PrivacyCardPage />, index: true },
        ],
      },
      {
        path: 'term-and-condition',
        children: [
          { element: <TermAndConditionCardPage />, index: true },
        ],
      },
      {
        path: 'FAQs',
        children: [
          { element: <FaqsCardPage />, index: true },
        ],
      },
      {
        path: 'contact-us',
        children: [
          { element: <ContactUsPage />, index: true },
        ],
      },
    ],
  },
];
