import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import reportServices from './reportServices';

const initialState = {
  incomeStatement: [],
  balanceSheet: [],
  isLoadingPdf: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};


export const incomeStatementGet = createAsyncThunk(
  'incomeStatementGet',
  async ({branch,startDate,endDate,refStartDate,refEndDate}, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await reportServices.incomeStatementGet(user.accesstoken,branch,startDate, endDate, refStartDate, refEndDate);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const balanceSheetGet = createAsyncThunk(
  'balanceSheetGet',
  async ({branch,balanceSheetPer,comparativeDataAs,accountType}, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await reportServices.balanceSheetGet(user.accesstoken,branch,balanceSheetPer,comparativeDataAs,accountType);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const incomeStatementPdf = createAsyncThunk(
  'incomeStatementPdf',
  async ({branch,startDate, endDate, refStartDate, refEndDate, changeAmount, changePercentage }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await reportServices.incomeStatementPdf(user.accesstoken,branch,startDate,endDate,refStartDate,refEndDate,changeAmount,changePercentage);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const balanceSheetPDF = createAsyncThunk(
  'balanceSheetPDF',
  async ({branch,balanceSheetPer,comparativeDataAs,accountType,changeAmount,changePercentage}, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await reportServices.balanceSheetPDF(user.accesstoken,branch,balanceSheetPer,comparativeDataAs,accountType,changeAmount,changePercentage);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isLoadingPdf =false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(incomeStatementGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(incomeStatementGet.fulfilled, (state, action) => {
        state.incomeStatement = action?.payload?.body;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(incomeStatementGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.incomeStatement = [];
      })
      .addCase(balanceSheetGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(balanceSheetGet.fulfilled, (state, action) => {
        state.balanceSheet = action?.payload?.body;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(balanceSheetGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.balanceSheet = [];
      })
      .addCase(incomeStatementPdf.pending, (state) => {
        state.isLoadingPdf = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(incomeStatementPdf.fulfilled, (state) => {
        state.isSuccess = true;
        state.isLoadingPdf = false;
        state.isError = false;
      })
      .addCase(incomeStatementPdf.rejected, (state, action) => {
        state.isLoadingPdf = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(balanceSheetPDF.pending, (state) => {
        state.isLoadingPdf = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(balanceSheetPDF.fulfilled, (state) => {
        state.isSuccess = true;
        state.isLoadingPdf = false;
        state.isError = false;
      })
      .addCase(balanceSheetPDF.rejected, (state, action) => {
        state.isLoadingPdf = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { resets } = reportSlice.actions;

export default reportSlice.reducer;
