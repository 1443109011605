import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const addAccountChart = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BASE_URL}/admin/accountChart`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Add Account Chart api failed');
};
const accountChart = async (token,pageno,limit,branch,search,sortField,sortOrder) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/accountChart?branch=${branch}&page=${pageno}&perPage=${limit}&search=${search??""}&sortField=${sortField}&sortOrder=${sortOrder}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Account Chart List api failed');
};
const updateAccountChart = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${BASE_URL}/admin/accountChart/${id}`, data, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Updated Account Chart api failed');
};
const activeAccountChart = async (token,branch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/admin/active/accountChart?branch=${branch}`, config);
  if (response.data) {
    return response.data;
  }
  throw new Error('Get Active Account Chart api failed');
};
const accountChartServices = {
    addAccountChart,
    accountChart,
    updateAccountChart,
    activeAccountChart,
};

export default accountChartServices;
