// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/admin',
  SUPERADMIN:'/superAdmin'
};

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/hjxMnGUJCjY7pX8lQbS7kn/%5BPreview%5D-Minimal-Web.v5.4.0?type=design&node-id=0-1&mode=design&t=2fxnS70DuiTLGzND-0',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (title) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      register: `${ROOTS.AUTH}/amplify/register`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `/dy882d`,
      register: `/llmidkes`,
      verify: `/verify`,
      newPassword: `/new-password`,
      forgotPassword: `/forgot-password`,
      verifyAccount:'/verify-account',
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },
  dashboard: {
    root: `${ROOTS.DASHBOARD}/dashboard`,
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    settings: `${ROOTS.DASHBOARD}/settings`,
    account_access: `${ROOTS.DASHBOARD}/account-access`,
    roleRoot: `${ROOTS.DASHBOARD}/roles&premissions`,
    role:`${ROOTS.DASHBOARD}/roles&premissions/role/list`,
    newRole:`${ROOTS.DASHBOARD}/roles&premissions/role/new`,
    premissions:`${ROOTS.DASHBOARD}/roles&premissions/premissions/list`,
    mailbox:`${ROOTS.DASHBOARD}/mailbox`,
    company:`${ROOTS.DASHBOARD}/company`,
    subscription:`${ROOTS.DASHBOARD}/subscription`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    accounting: {
      root: `${ROOTS.DASHBOARD}/accounting`,
      new: `${ROOTS.DASHBOARD}/accounting/new`,
      transaction: `${ROOTS.DASHBOARD}/accounting/transaction`,
      cards: `${ROOTS.DASHBOARD}/accounting/cards`,
      profile: `${ROOTS.DASHBOARD}/accounting/profile`,
      account: `${ROOTS.DASHBOARD}/accounting/account`,
      vat_declaration : `${ROOTS.DASHBOARD}/accounting/vat-declaration`,
      balance_tax_rate : `${ROOTS.DASHBOARD}/accounting/balance-tax-rate`,
      year_end_procedure: `${ROOTS.DASHBOARD}/accounting/year-end-procedure`,
      edit: (id) => `${ROOTS.DASHBOARD}/accounting/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/accounting/${MOCK_ID}/edit`,
      },
    },
    payroll: {
      root: `${ROOTS.DASHBOARD}/payroll`,
      new: `${ROOTS.DASHBOARD}/payroll/new`,
      list: `${ROOTS.DASHBOARD}/payroll/list`,
      cards: `${ROOTS.DASHBOARD}/payroll/cards`,
      profile: `${ROOTS.DASHBOARD}/payroll/profile`,
      account: `${ROOTS.DASHBOARD}/payroll/account`,
      edit: (id) => `${ROOTS.DASHBOARD}/payroll/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/payroll/${MOCK_ID}/edit`,
      },
    },
    reports: {
      root: `${ROOTS.DASHBOARD}/reports`,
      vat:`${ROOTS.DASHBOARD}/reports/vat`,
      incomeStatement:`${ROOTS.DASHBOARD}/reports/income-statement`,
      balanceSheet:`${ROOTS.DASHBOARD}/reports/balance-sheet`,
      journal:(id) => `${ROOTS.DASHBOARD}/reports/journal/${id}`,
    },
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
      },
    },
    contact: {
      root: `${ROOTS.DASHBOARD}/contact`,
      new: `${ROOTS.DASHBOARD}/contact/new`,
      edit: (id) => `${ROOTS.DASHBOARD}/contact/${id}/edit`,
    },
  },
  superAdmin: {
    root: `${ROOTS.SUPERADMIN}/dashboard`,
    company:`${ROOTS.SUPERADMIN}/company`,
    user:`${ROOTS.SUPERADMIN}/user`,
    subscriptionPlan:`${ROOTS.SUPERADMIN}/subscription-plan`,
    aboutUs:`${ROOTS.SUPERADMIN}/aboutUs`,
    privacyPolicy:`${ROOTS.SUPERADMIN}/privacy-policy`,
    termAndCondition:`${ROOTS.SUPERADMIN}/term-and-condition`,
    faqs:`${ROOTS.SUPERADMIN}/FAQs`,
    contactUs:`${ROOTS.SUPERADMIN}/contact-us`,
  },
};
