import axios from 'axios';

const BASE_URL = process.env.REACT_APP_HOST_API;

const incomeStatementGet = async (token, branch, startDate, endDate, refStartDate, refEndDate) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/financialStatement/incomeStatement?branch=${branch}&startDate=${startDate ?? ''
    }&endDate=${endDate ?? ''}&refStartDate=${refStartDate ?? ''}&refEndDate=${refEndDate ?? ""}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Get Income Statement api failed');
};
const balanceSheetGet = async (token, branch, balanceSheetPer, comparativeDataAs, accountType) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/financialStatement/balanceSheet?branch=${branch}&balanceSheetPer=${balanceSheetPer ?? ''
    }&comparativeDataAs=${comparativeDataAs ?? ''}&accountType=${accountType ?? ''}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Get Balance Sheet api failed');
};
const incomeStatementPdf = async (token, branch, startDate, endDate, refStartDate, refEndDate, changeAmount, changePercentage) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  };
  const response = await axios.get(
    `${BASE_URL}/admin/financialStatement/incomeStatement/pdf?branch=${branch}&startDate=${startDate ?? ''
    }&endDate=${endDate ?? ''}&refStartDate=${refStartDate ?? ''}&refEndDate=${refEndDate ?? ""}&changeAmount=${changeAmount ?? ''}&changePercentage=${changePercentage ?? ''}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Income Statement PDF api failed');
};
const balanceSheetPDF = async (token, branch, balanceSheetPer, comparativeDataAs, accountType,changeAmount,changePercentage) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  };
  const response = await axios.get(
    `${BASE_URL}/admin/financialStatement/balanceSheet/pdf?branch=${branch}&balanceSheetPer=${balanceSheetPer ?? ''
    }&comparativeDataAs=${comparativeDataAs ?? ''}&accountType=${accountType ?? ''}&changeAmount=${changeAmount ?? ''}&changePercentage=${changePercentage ?? ''}`,
    config
  );
  if (response.data) {
    return response.data;
  }
  throw new Error('Income Statement PDF api failed');
};

const reportServices = {
  incomeStatementGet,
  balanceSheetGet,
  incomeStatementPdf,
  balanceSheetPDF
};

export default reportServices;
