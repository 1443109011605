import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userServices from './userServices';

const initialState = {
  users: [],
  activeUser:0,
  inActiveUser:0,
  accountAccess:[],
  totalUsers:0,
  totalRecord: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

export const addUser = createAsyncThunk('addUser', async (data, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await userServices.addUser(user.accesstoken, data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const userGet = createAsyncThunk(
  'userGet',
  async ({pageno,branch,limit,role,search,isactive}, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await userServices.userGet(user.accesstoken,pageno,limit,branch,role,search,isactive);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const userStatsGet = createAsyncThunk('userStatsGet', async (branch, thunkAPI) => {
  try {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return await userServices.userStatsGet(user.accesstoken, branch);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateUser = createAsyncThunk(
  'updateUser',
  async ({ id, data }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await userServices.updateUser(user.accesstoken, id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const allUserGet = createAsyncThunk(
  'allUserGet',
  async ({pageno,limit,search}, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await userServices.allUserGet(user.accesstoken,pageno,limit,search);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const userAccessBranchGet = createAsyncThunk(
  'userAccessBranchGet',
  async ({ pageno, limit }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      return await userServices.userAccessBranchGet(user.accesstoken, pageno, limit);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resets: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addUser.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(addUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(userGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(userGet.fulfilled, (state, action) => {
        state.users = action?.payload?.body;
        state.totalRecord = action?.payload?.total_record;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(userGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.users = [];
      })
      .addCase(userStatsGet.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(userStatsGet.fulfilled, (state, action) => {
        state.totalUsers = action?.payload?.body?.totalUsers;
        state.activeUser = action?.payload?.body?.activeUsers;
        state.inActiveUser=action?.payload?.body?.inactiveUsers;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(userStatsGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.users = state.users.map((item) =>
          item._id === action?.payload?.body?._id ? action?.payload?.body : item
        );
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(allUserGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(allUserGet.fulfilled, (state, action) => {
        state.users = action?.payload?.body;
        state.totalRecord = action?.payload?.total_record;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(allUserGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.users = [];
      })
      .addCase(userAccessBranchGet.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(userAccessBranchGet.fulfilled, (state, action) => {
        state.accountAccess = action?.payload?.body;
        state.totalRecord = action?.payload?.total_record;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(userAccessBranchGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.accountAccess = [];
      })
      ;  
  },
});

export const { resets } = userSlice.actions;

export default userSlice.reducer;
